import React from "react";

interface IRefProps {
  value?: string;
}

const ReadOnlyField = React.forwardRef<HTMLButtonElement, IRefProps>(
  ({ value }, ref) => <span ref={ref}>{value || "-"}</span>
);

export default ReadOnlyField;
