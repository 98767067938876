import React, { ReactElement, useEffect } from "react";
import { PageHeader, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import ErrorAlert from "src/components/error-alert";
import { IVinylUser } from "src/api/get-user";
import NotFound from "../not-found";
import UserForm from "./components/user-form";

interface IProps {
  user: IVinylUser | undefined;
  isError: boolean;
  getData: () => void;
  onChange: (updatedData: IVinylUser) => void;
  onSave: () => Promise<void>;
  isSavePending: boolean;
}

const User = ({
  user,
  isError,
  getData,
  onChange,
  onSave,
  isSavePending,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = !user;

  if (isError) {
    return <NotFound />;
  }

  const renderContents = () => {
    if (isLoading) return <Skeleton active />;
    if (isError)
      return (
        <ErrorAlert
          getData={getData}
          errorMessage={t(
            "We've been unable to get the details for this user."
          )}
        />
      );
    return (
      <UserForm
        data={user as IVinylUser}
        onChange={onChange}
        onSave={onSave}
        isSavePending={isSavePending}
      />
    );
  };

  return (
    <PageHeader ghost={false} title={t("Edit User")}>
      {renderContents()}
    </PageHeader>
  );
};

export default User;
