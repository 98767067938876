export enum RequestTagColor {
  Approved = "success",
  Pending = "processing",
  Rejected = "warning",
  Deleted = "error",
  Default = "default",
}

export enum OrganisationRequestStatus {
  Approved = "Approved",
  Rejected = "Rejected",
  Deleted = "Deleted",
  InVerification = "InVerification",
}

export const getTagColor = (status: OrganisationRequestStatus): string => {
  switch (status) {
    case OrganisationRequestStatus.InVerification:
      return RequestTagColor.Pending;
    case OrganisationRequestStatus.Approved:
      return RequestTagColor.Approved;
    case OrganisationRequestStatus.Rejected:
      return RequestTagColor.Rejected;
    case OrganisationRequestStatus.Deleted:
      return RequestTagColor.Deleted;
    default:
      return RequestTagColor.Default;
  }
};

export const needsAction = (status: OrganisationRequestStatus): boolean =>
  status === OrganisationRequestStatus.InVerification;
