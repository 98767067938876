import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Layout, Menu } from "antd";
import { useLocation, Link } from "react-router-dom";
import styles from "./styles";

interface IProps {
  children: ReactElement;
  isLoggedIn: boolean;
}

const Header = ({ children, isLoggedIn }: IProps): ReactElement => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Layout.Header className="header">
      <div className="header__user" css={styles.user}>
        {children}
      </div>

      {isLoggedIn && (
        <Menu theme="dark" mode="horizontal" selectedKeys={[location.pathname]}>
          <Menu.Item key="/organization-requests">
            <Link to="/organization-requests">
              {t("Organization Requests")}
            </Link>
          </Menu.Item>
          <Menu.Item key="/organizations">
            <Link to="/organizations">{t("Organizations")}</Link>
          </Menu.Item>
        </Menu>
      )}
    </Layout.Header>
  );
};

export default Header;
