import React, { ReactElement } from "react";
import { Alert, Button } from "antd";
import { useTranslation } from "react-i18next";

interface IProps {
  getData: () => void;
  errorMessage: string;
}

const ErrorAlert = ({ getData, errorMessage }: IProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Alert
      message={t("An error has occurred")}
      description={
        <div>
          <p>{errorMessage}</p>
          <Button type="primary" onClick={() => getData()}>
            {t("Please try again")}
          </Button>
        </div>
      }
      type="error"
      showIcon
    />
  );
};

export default ErrorAlert;
