import { css } from "@emotion/react";

export default {
  menu: css`
    background-color: transparent;
    border: none;
    color: rgba(255, 255, 255, 0.65);
    cursor: pointer;
    outline: none;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #fff;
    }
  `,
  avatar: css`
    border: 2px solid #fff;
  `,
};
