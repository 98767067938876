import axios from "axios";
import config from "../config";
import { IVinylUser } from "./get-user";
import { getAuthHeader, getJsonContentHeader } from "./headers";

interface IGetUsers {
  users: IVinylUser[];
}

function getUsers(
  accessToken: string,
  organizationId: string
): Promise<IVinylUser[]> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };
  return axios
    .get(
      `${config.organizationsService.url}/organisations/${organizationId}/users`,
      options
    )
    .then(({ data }: { data: IGetUsers }) => {
      return data.users;
    });
}

export default getUsers;
