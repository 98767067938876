import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common/lib/utils/media-queries";

export default {
  loading: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  loadingAnimation: css`
    transform: scale(0.5);

    @media ${queries.xxLarge} {
      transform: scale(1);
    }
  `,
};
