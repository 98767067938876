import { IOrganization, IAdminUser } from "src/interfaces/organization";
import { IVinylUser } from "src/api/get-user";

const isAdminUser = (user: IVinylUser, organization: IOrganization): boolean =>
  organization.adminUsers.some(({ email }) => email === user.email);

const addAdminUser = (user: IVinylUser, organization: IOrganization) => {
  if (isAdminUser(user, organization)) return organization;

  return {
    ...organization,
    adminUsers: organization.adminUsers.concat({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phoneNumber,
    } as IAdminUser),
  };
};

const removeAdminUser = (user: IVinylUser, organization: IOrganization) => {
  if (!isAdminUser(user, organization)) return organization;

  return {
    ...organization,
    adminUsers: organization.adminUsers.filter(
      (adminUser) => adminUser.email !== user.email
    ),
  };
};

const updateOrganizationAdmins = (
  isAdmin: boolean,
  user: IVinylUser,
  organization: IOrganization
): IOrganization => {
  let updatedOrganization = organization;
  if (isAdmin) {
    updatedOrganization = addAdminUser(user, organization);
  } else {
    updatedOrganization = removeAdminUser(user, organization);
  }

  return updatedOrganization;
};

export { isAdminUser, updateOrganizationAdmins };
