export enum OrganizationRequestStatus {
  Approved = "Approved",
  Rejected = "Rejected",
  Deleted = "Deleted",
  InVerification = "InVerification",
}

export enum RequesterRole {
  AccountManager = "AccountManager",
  Creative = "Creative",
  Filmmaker = "Filmmaker",
  LicensingManager = "LicensingManager",
  Marketing = "Marketing",
  MusicSupervisor = "MusicSupervisor",
  Procurement = "Procurement",
  VideoEditor = "VideoEditor",
  Other = "Other",
}

export enum OrganizationType {
  AdvertisingAgency = "AdvertisingAgency",
  MusicSupervisionAgency = "MusicSupervisionAgency",
  MusicSupervisionIndependent = "MusicSupervisionIndependent",
  ProductBrand = "ProductBrand",
  ProductionTV = "ProductionTV",
  ProductionFilm = "ProductionFilm",
  ProductionVideoGames = "ProductionVideoGames",
  Other = "Other",
}

export enum ModalActions {
  approve = "approve",
  reject = "reject",
}
