import { css } from "@emotion/react";

export default {
  checkIcon: css`
    color: orange;
    font-size: 22px;
    float: left;
    margin-left: 25px;
  `,
  table: css`
    margin: 40px auto;
    width: 1024px;
  `,
};
