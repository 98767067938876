import React, { FunctionComponent } from "react";
import config from "../config";
import Auth0Provider from "./provider";
import { getRedirectUri } from "./utils";

const scopes = "org:rw orgrequests:rw orgusers:rw";

const withAuthentication = (
  WrappedComponent: FunctionComponent
): FunctionComponent => (props: Record<string, unknown>) => (
  <Auth0Provider
    domain={config.auth0.domain}
    clientId={config.auth0.clientId}
    appName={config.auth0.appName}
    tokenAudience={config.auth0.audience}
    scopes={scopes}
    redirectUri={getRedirectUri(config.auth0.redirectUri)}
  >
    <WrappedComponent {...props} />
  </Auth0Provider>
);

export default withAuthentication;
