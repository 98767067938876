import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton, Table } from "antd";
import { IUserTableRow } from "src/interfaces/users-table";
import { IVinylUser } from "src/api/get-user";
import { IOrganization } from "src/interfaces/organization";
import NotFound from "../../../not-found";
import ErrorAlert from "../../../../components/error-alert";
import getColumns from "./get-columns";
import styles from "./styles";

const tableContentFor = (condition: boolean, emptyText: ReactElement) =>
  condition ? { locale: { emptyText } } : {};

const getDataSource = (
  users: Array<IVinylUser>,
  organization: IOrganization
): IUserTableRow[] => {
  if (!Array.isArray(users)) return [];

  return users.map((user) => {
    const admin = organization.adminUsers.find(
      (adminUser) => adminUser.email === user.email
    );
    return {
      ...user,
      id: user.userId,
      name: user.fullName,
      key: user.userId,
      organizationId: organization.id,
      isAdmin: !!admin,
      phoneNumber: admin ? user.phoneNumber : undefined,
    };
  });
};

interface IProps {
  getData: () => void;
  organization: IOrganization;
  users?: Array<IVinylUser>;
  isError: boolean;
}

const UsersTable = (props: IProps): ReactElement => {
  const { t } = useTranslation<string>();
  const { users, getData, organization, isError } = props;
  const isLoading = !users;

  if (isLoading) return <Skeleton active />;

  if (isError || !users) {
    return <NotFound />;
  }

  const dataSource = getDataSource(users, organization);
  const columns = getColumns();

  const errorTableProps = tableContentFor(
    isError,
    <ErrorAlert
      getData={getData}
      errorMessage={t(
        "We've been unable to get the list of organization users."
      )}
    />
  );

  return (
    <Table
      css={styles.table}
      columns={columns}
      dataSource={dataSource}
      {...errorTableProps}
      pagination={{ hideOnSinglePage: true }}
    />
  );
};

export default UsersTable;
