import { IGetOrganisationRequestDto } from "@songtradr/vinyl-common/lib/organisation";
import axios from "axios";
import config from "../config";
import { getAuthHeader, getJsonContentHeader } from "./headers";

function getOrganizationRequests(
  accessToken: string,
  searchQuery = {}
): Promise<Array<IGetOrganisationRequestDto>> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };
  return axios
    .post(
      `${config.organizationsService.url}/organisations/requests/_search`,
      searchQuery,
      options
    )
    .then(({ data }) => data as Array<IGetOrganisationRequestDto>);
}

export default getOrganizationRequests;
