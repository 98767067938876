import React, { ReactElement, useState } from "react";
import { Modal, Button, notification, message } from "antd";
import { useTranslation, Trans } from "react-i18next";
import { ModalActions } from "src/constants/organization-requests";
import { IPatchOrganisationRequestDto } from "src/interfaces/organization-requests";
import IOrganisationRequest from "@songtradr/vinyl-common/lib/organisation/request/organisation-request.interface";
import NewOrganizationForm from "../new-organization-form";
import RejectRequestForm from "../reject-request-form";

interface IProps {
  action?: ModalActions;
  onConfirmAction: () => Promise<void>;
  onDismissAction: () => void;
  activeRequest?: IPatchOrganisationRequestDto;
  isActionPending: boolean;
  updateActiveRequest: (
    updatedActiveRequest: IPatchOrganisationRequestDto
  ) => void;
  createNewActiveRequest: (createNewRequest: IOrganisationRequest) => void;
}

const ActionModal = ({
  action,
  onConfirmAction,
  onDismissAction,
  activeRequest,
  isActionPending,
  updateActiveRequest,
  createNewActiveRequest,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(false);

  const onConfirmCreate = async () => {
    if (activeRequest) {
      try {
        await onConfirmAction();
        void message.success(
          <Trans i18nKey="createOrganization##success">
            Organization <strong>{activeRequest.name}</strong> created
            successfully
          </Trans>
        );
      } catch (e) {
        notification.error({
          message: t("Error creating organization"),
          description: t(
            "An error has ocurred when creating the organization. Please check that the organization has been created successfully."
          ),
          duration: 0,
        });
      }
    }
  };

  const onConfirmReject = async () => {
    if (activeRequest) {
      try {
        await onConfirmAction();
        void message.success(
          <Trans i18nKey="rejectOrganization##success">
            Request for organization <strong>{activeRequest.name}</strong> has
            been denied
          </Trans>
        );
      } catch (e) {
        notification.error({
          message: t("Error updating request"),
          description: t(
            "An error has ocurred when updating the original request. Please check that the request has been set as rejected."
          ),
          duration: 0,
        });
      }
    }
  };

  const commonProps = {
    onCancel: onDismissAction,
    maskClosable: !isActionPending,
    closable: !isActionPending,
    destroyOnClose: true,
    width: "50%",
    style: { maxWidth: 768, minWidth: 512 },
  };

  const cancelButton = (
    <Button key="cancel" onClick={onDismissAction} disabled={isActionPending}>
      {t("Cancel")}
    </Button>
  );

  const acceptButton = (
    <Button
      key="accept"
      type="primary"
      onClick={onConfirmCreate}
      loading={isActionPending}
      disabled={!isValid}
    >
      {t("Create Organization")}
    </Button>
  );

  const rejectButton = (
    <Button
      key="reject"
      type="primary"
      onClick={onConfirmReject}
      loading={isActionPending}
      disabled={!isValid}
    >
      {t("Deny Request")}
    </Button>
  );

  return (
    <>
      <Modal
        title={t("Confirm details to create a new organization")}
        visible={action === ModalActions.approve}
        footer={[cancelButton, acceptButton]}
        onOk={onConfirmCreate}
        {...commonProps}
      >
        <NewOrganizationForm
          data={activeRequest}
          onChange={(isUpdateValid, updatedRequest) => {
            if (updatedRequest) {
              setIsValid(isUpdateValid);
              createNewActiveRequest(updatedRequest);
            }
          }}
        />
      </Modal>
      <Modal
        title={t("Provide details for denying the organization request")}
        visible={action === ModalActions.reject}
        footer={[cancelButton, rejectButton]}
        onOk={onConfirmReject}
        {...commonProps}
      >
        <RejectRequestForm
          data={activeRequest}
          onChange={(isUpdateValid, updatedRequest) => {
            setIsValid(isUpdateValid);
            updateActiveRequest(updatedRequest);
          }}
        />
      </Modal>
    </>
  );
};

export default ActionModal;
