import { css } from "@emotion/react";

export default {
  approve: css`
    font-size: 1rem;
    color: #52c41a;
  `,
  reject: css`
    font-size: 1rem;
    color: #f5222d;
  `,
};
