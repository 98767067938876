import { OrganizationTypes } from "src/constants";
import { IOrganization } from "src/interfaces/organization";

export enum OrganizationStatuses {
  Created = "Created",
  Deleted = "Deleted",
}

export enum OrganizationTagColor {
  Success = "success",
  Error = "error",
  Default = "default",
}

export const getTagColor = (status: OrganizationStatuses | string): string => {
  switch (status) {
    case OrganizationStatuses.Created:
      return OrganizationTagColor.Success;
    case OrganizationStatuses.Deleted:
      return OrganizationTagColor.Error;
    default:
      return OrganizationTagColor.Default;
  }
};

export const mockOrganization = (): IOrganization => {
  const date = new Date();
  return {
    id: "RqztjSdHcU",
    version: 1,
    orgRequestId: "1223234324",
    audit: [
      {
        dateTime: date,
        description: "test",
        details: "test data",
      },
    ],
    name: "Nike",
    status: "Created",
    orgType: OrganizationTypes.ProductBrand,
    parentCompany: {
      name: "Test Company",
      id: "13444324",
      version: 1,
      audit: [],
    },
    adminUsers: [],
  };
};
