import { IAuthenticatedUser } from "@songtradr/spa-common/lib/interfaces";
import React from "react";

export interface IAuthContext {
  isLoading?: boolean;
  isAuthenticated?: boolean;
  error?: {
    error: string;
    error_description: string;
  } | null;
  dismissError?: () => void;
  user?: IAuthenticatedUser | null;
  login?: (target?: string, signUpFirst?: boolean) => Promise<void>;
  logout?: () => void;
  getAccessToken?: () => Promise<string>;
  refresh?: () => void;
}

export default React.createContext<IAuthContext>({});
