import { IOrganization, IParentCompany } from "src/interfaces/organization";

export const setParentCompanyLoading = (
  organization: IOrganization
): IOrganization => {
  return {
    ...organization,
    parentCompany: {
      ...organization.parentCompany,
      isLoading: true,
    },
  };
};

export const setParentCompanyLoaded = (
  organization: IOrganization,
  parentCompany: IParentCompany
): IOrganization => {
  return {
    ...organization,
    parentCompany: {
      id: parentCompany.id,
      name: parentCompany.name,
      isLoading: false,
    },
  };
};

export const getUpdatedParentCompany = (
  organization: IOrganization,
  updatedOrganization: IOrganization
): IParentCompany | null =>
  updatedOrganization.parentCompany
    ? {
        ...organization.parentCompany,
        ...updatedOrganization.parentCompany,
      }
    : null;
