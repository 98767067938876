import { css } from "@emotion/react";

export default {
  form: css`
    margin: 0 auto;
    max-width: 1024px;
    width: 100%;
  `,
  controls: css`
    text-align: right;
  `,
};
