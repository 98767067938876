const config = {
  production: {
    production: true,
    auth0: {
      domain: "identity.songtradr.com",
      clientId: "4UGvVDuTaTv7nWXmYB1LC3bvWQqU43dk",
      audience: "songtradr-backend-api",
      redirectUri: "/",
      appName: "vinyl-app",
    },
    organizationsService: {
      url: "https://api.songtradr.net/onboarding",
    },
  },
  staging: {
    staging: true,
    auth0: {
      domain: "identity.staging-2.songtradr.dev",
      clientId: "1px2gKWQHGgrvxOeR4p5R2gj8ECFyVrj",
      audience: "songtradr-backend-api",
      redirectUri: "/",
      appName: "vinyl-app",
    },
    organizationsService: {
      url: "https://api.staging-2.songtradr.dev/onboarding",
    },
  },
  development: {
    development: true,
    auth0: {
      domain: "identity.dev.songtradr.dev",
      clientId: "gcln0dlisSuxl9ZNrOX4L0k6p9VzFh3D",
      audience: "songtradr-backend-api",
      redirectUri: "/",
      appName: "vinyl-app",
    },
    organizationsService: {
      url: process.env.REACT_APP_LOCAL_SERVICE
        ? "http://localhost:5000/onboarding"
        : "https://api.dev.songtradr.dev/onboarding",
    },
  },
};

export default config[process.env.REACT_APP_SETTINGS] || config.development;
