import axios, { AxiosRequestConfig } from "axios";
import { getAuthHeader, getJsonContentHeader } from "./headers";
import config from "../config";

function getUser(
  accessToken: string,
  userId: string,
  organizationId: string
): Promise<IVinylUser> {
  const options: AxiosRequestConfig = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };
  return axios
    .get(
      `${config.organizationsService.url}/organisations/${organizationId}/users/${userId}`,
      options
    )
    .then(({ data }) => data as IVinylUser);
}

export default getUser;

export interface IIdentity {
  accessToken: string;
  accessTokenSecret: string;
  connection: string;
  expiresIn: number;
  isSocial: boolean;
  profileData: Record<string, unknown>;
  provider: string;
  refreshToken: string;
  userId: string;
}

export interface IVinylUser {
  blocked: boolean;
  createdAt: Date;
  email: string;
  emailVerified: boolean;
  firstName: string;
  fullName: string;
  identities: IIdentity[];
  lastName: string;
  lastIpAddress: string;
  lastLogin: Date;
  lastPasswordReset: Date;
  locale: string;
  loginsCount: string;
  multifactor: string[];
  nickName: string;
  phoneNumber: string | undefined;
  phoneVerified: boolean;
  picture: string;
  providerAttributes: Record<string, unknown>;
  updatedAt: Date;
  userId: string;
  userName: string;
  isAdmin?: boolean;
}
