import React, { useState, useCallback, useRef } from "react";
import debounce from "lodash/debounce";
import { useMountedRef } from "@songtradr/spa-common/lib/utils";
import { IOrganization } from "src/interfaces/organization";
import { getOrganizations } from "../../api";
import useAuth from "../../auth/use-auth";
import ParentCompanySearchSelect from ".";

const ParentCompanySearchSelectContainer = React.forwardRef(
  (props, ref: React.ForwardedRef<HTMLSelectElement>) => {
    const { getAccessToken } = useAuth();
    const isMountedRef = useMountedRef();
    const [parentCompanies, setParentCompanies] = useState<
      Array<IOrganization>
    >([]);
    const [isFetching, setIsFetching] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const requestIdRef = useRef(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSearch = useCallback(
      debounce(async (name: string) => {
        if (!isMountedRef.current) return;

        setParentCompanies([]);
        setSearchTerm(name);
        if (!name) return;

        requestIdRef.current += 1;
        const requestId = requestIdRef.current;
        setIsFetching(true);

        let organizations: Array<IOrganization>;
        try {
          const accessToken = getAccessToken && (await getAccessToken());
          if (!accessToken) return;
          organizations = await getOrganizations(accessToken, { name });
        } catch (e) {
          organizations = [];
        }

        const isOldRequest = requestIdRef.current !== requestId;
        if (isOldRequest || !isMountedRef.current || !organizations) return;

        setParentCompanies(organizations);
        setIsFetching(false);
      }, 500),
      [isMountedRef, getAccessToken]
    );

    return (
      <ParentCompanySearchSelect
        ref={ref}
        {...props}
        onSearch={onSearch}
        isFetching={isFetching}
        searchTerm={searchTerm}
        parentCompanies={parentCompanies}
      />
    );
  }
);

export default ParentCompanySearchSelectContainer;
