import React, { useState, useEffect, ReactElement, useCallback } from "react";
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
} from "@auth0/auth0-spa-js";
import {
  IAuthenticatedUser,
  IOauthUser,
} from "@songtradr/spa-common/lib/interfaces";
import Auth0Context from "./context";
import {
  isRedirectedBackFromLogin,
  onRedirectCallback,
  getRedirectConfig,
  getRedirectTargetPath,
  transformUser,
} from "./utils";

let auth0Client: Auth0Client;

interface IProps {
  domain: string;
  clientId: string;
  appName: string;
  tokenAudience: string;
  scopes: string;
  redirectUri: string;
  children: ReactElement;
}

const Auth0Provider = ({
  domain,
  clientId,
  appName,
  tokenAudience,
  scopes,
  redirectUri,
  children,
}: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<null | IAuthenticatedUser>(null);
  const [error, setError] = useState(null);

  const login = (target?: string, signUpFirst?: boolean) =>
    auth0Client.loginWithRedirect({
      ...getRedirectConfig(target || getRedirectTargetPath(redirectUri)),
      screen_hint: signUpFirst && "signUp",
      appName,
      appOrigin: window.location.origin,
    });

  const logout = () =>
    auth0Client.logout({
      returnTo: window.location.origin,
    });

  const setupAuthClient = useCallback(async () => {
    const auth0ClientOptions: Auth0ClientOptions = {
      domain,
      client_id: clientId,
      scope: scopes,
      audience: tokenAudience,
      redirect_uri: redirectUri,
      cacheLocation: "localstorage",
      useRefreshTokens: true,
    };

    try {
      auth0Client = await createAuth0Client(auth0ClientOptions);
    } catch (e) {
      // Something has gone wrong when the SDK has attempted to create an
      // Auth0 client and have it set up the correct authentication status for
      // the user. In this bad state, there's not much we can do but force a
      // log out on the user so that they can log in again.
      auth0Client = new Auth0Client(auth0ClientOptions);
      logout();
      return;
    }

    if (isRedirectedBackFromLogin()) {
      let appState: { targetPath: string } | undefined;
      try {
        const response = await auth0Client.handleRedirectCallback();
        appState = response.appState as { targetPath: string } | undefined;
      } catch (e) {
        setError(e);
      }
      onRedirectCallback(appState);
    }

    const userIsAuthenticated = await auth0Client.isAuthenticated();
    if (userIsAuthenticated) {
      const authenticatedUser = await (auth0Client.getUser() as Promise<IOauthUser>);
      setUser(transformUser(authenticatedUser));
    }

    setIsAuthenticated(userIsAuthenticated);
    setIsLoading(false);
  }, [clientId, domain, redirectUri, scopes, tokenAudience]);

  const refresh = async () => {
    await auth0Client.getTokenSilently({
      ignoreCache: true,
    });
    void setupAuthClient();
  };

  useEffect(() => {
    void setupAuthClient();
  }, [setupAuthClient]);

  return (
    <Auth0Context.Provider
      value={{
        getAccessToken: async () => {
          try {
            return await (auth0Client.getTokenSilently() as Promise<string>);
          } catch (e) {
            // Something has gone wrong when attempting to get the token
            // (e.g. the refresh token has been revoked). In this state, there's
            // not much we can do but force a log out on the user so that they
            // can log in again.
            logout();

            // Throw the error so that execution stops and we're not trying to
            // make a request to the service with a missing token.
            throw e;
          }
        },
        isLoading,
        isAuthenticated,
        user,
        error,
        dismissError: () => {
          setError(null);
        },
        login,
        logout,
        refresh,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default Auth0Provider;
