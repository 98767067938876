import axios from "axios";
import { IOrganization } from "src/interfaces/organization";
import config from "../config";
import { getAuthHeader, getJsonContentHeader } from "./headers";

function getOrganizations(
  accessToken: string,
  searchQuery = {}
): Promise<IOrganization[]> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };
  return axios
    .post(
      `${config.organizationsService.url}/organisations/_search`,
      searchQuery,
      options
    )
    .then(({ data }) => data as Array<IOrganization>);
}

export default getOrganizations;
