import React, { useEffect, ReactElement } from "react";
import { Route, withRouter, RouteProps, useRouteMatch } from "react-router-dom";
import PageLoading from "../components/page-loading";
import useAuth from "./use-auth";

const AuthenticatedRoute = (props: RouteProps): ReactElement => {
  const { isAuthenticated, login } = useAuth();
  const { location, path } = props;
  const match = useRouteMatch(path as string);

  useEffect(() => {
    void (async () => {
      if (isAuthenticated || !match) return undefined;
      return login && login(location ? location.pathname : undefined);
    })();
  }, [isAuthenticated, login, location, match]);

  return (
    <Route
      {...props}
      {...(isAuthenticated ? {} : { children: <PageLoading /> })}
    />
  );
};

const WrappedAuthenticatedRoute = withRouter(AuthenticatedRoute);
export default (props: RouteProps): ReactElement => (
  <WrappedAuthenticatedRoute {...props} />
);
