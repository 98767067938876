import React, { ReactElement } from "react";
import { Tag } from "antd";
import { TFunction } from "react-i18next/*";
import {
  IRequester,
  IRequestDataSource,
} from "src/interfaces/organization-requests";
import { OrganisationRequestStatus } from "src/pages/organization-requests/utils";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import ActionDropdown from "./components/action-dropdown";
import { getTagColor, needsAction } from "./utils";

interface IColumnData {
  title: string;
  dataIndex: string;
  key: string;
  render:
    | ((name: string) => EmotionJSX.Element)
    | ((requester: IRequester) => EmotionJSX.Element)
    | ((status: OrganisationRequestStatus) => EmotionJSX.Element)
    | ((
        status: OrganisationRequestStatus,
        request: IRequestDataSource
      ) => ReactElement | string);
}

const getColumns = (t: TFunction<string>): Array<IColumnData> => [
  {
    title: t("Name"),
    dataIndex: "name",
    key: "name",
    render: (name: string) => <strong>{name}</strong>,
  },
  {
    title: t("Organization Type"),
    dataIndex: "orgType",
    key: "orgType",
    render: (type: string) => (
      <>{t([`organizationTypes##${type}`, "organizationTypes##unknown"])}</>
    ),
  },
  {
    title: t("Role"),
    dataIndex: "requester",
    key: "requester-role",
    render: (requester: IRequester) => (
      <>{t([`requesterRoles##${requester.role}`, "requesterRoles##unknown"])}</>
    ),
  },
  {
    title: t("Email"),
    dataIndex: "requester",
    key: "requester-email",
    render: (requester: IRequester) => (
      <a href={`mailto:${requester.email}`}>{requester.email}</a>
    ),
  },
  {
    title: t("Phone"),
    dataIndex: "requester",
    key: "requester-phone",
    render: (requester: IRequester) => <>{requester.phone}</>,
  },
  {
    title: t("Status"),
    dataIndex: "status",
    key: "status",
    render: (status: OrganisationRequestStatus) => (
      <Tag color={getTagColor(status)}>
        {t([
          `organizationRequestStatus##${status}`,
          "organizationRequestStatus##unknown",
        ])}
      </Tag>
    ),
  },
  {
    title: t("Action"),
    dataIndex: "status",
    key: "action",
    render: (
      status: OrganisationRequestStatus,
      request: IRequestDataSource
    ) => {
      if (!needsAction(status)) return "-";
      return <ActionDropdown request={request} />;
    },
  },
];

export default getColumns;
