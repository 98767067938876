import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Tag } from "antd";
import { OrganizationType } from "src/constants/organization-requests";
import { IAdminUser, IParentCompany } from "src/interfaces/organization";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { TFunction } from "react-i18next/*";
import { getTagColor } from "./utils";

interface IColumnData {
  title: string;
  dataIndex: string;
  key: string;
  render:
    | ((name: string, { id }: { id: string }) => ReactElement)
    | ((status: string) => ReactElement)
    | ((type: OrganizationType) => ReactElement)
    | ((parentCompany: IParentCompany) => EmotionJSX.Element | string)
    | ((admins: Array<IAdminUser>) => ReactElement);
}

const getColumns = (t: TFunction<string>): Array<IColumnData> => [
  {
    title: t("Name"),
    dataIndex: "name",
    key: "name",
    render: (name, { id }) => <Link to={`/organizations/${id}`}> {name} </Link>,
  },
  {
    title: t("Status"),
    dataIndex: "status",
    key: "status",
    render: (status: string) => (
      <Tag color={getTagColor(status)}>
        {t([`organizationStatus##${status}`, "organizationStatus##unknown"])}
      </Tag>
    ),
  },
  {
    title: t("Organization Type"),
    dataIndex: "orgType",
    key: "org-type",
    render: (type: OrganizationType) => (
      <>{t([`organizationTypes##${type}`, "organizationTypes##unknown"])}</>
    ),
  },
  {
    title: t("Parent Company"),
    dataIndex: "parentCompany",
    key: "action",
    render: (parentCompany: IParentCompany) => {
      if (!parentCompany) return t("N/A");
      return (
        <Link to={`/organizations/${parentCompany.id}`}>
          {t("View Parent")}
        </Link>
      );
    },
  },

  {
    title: t("Admin User(s)"),
    dataIndex: "adminUsers",
    key: "adminUsers",
    render: (admins: Array<IAdminUser>) => (
      <ul>
        {admins.map((admin) => (
          <li key={admin.email}>
            {admin.firstName} {admin.lastName}
          </li>
        ))}
      </ul>
    ),
  },
];

export default getColumns;
