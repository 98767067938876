import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Result } from "antd";
import { LoginOutlined } from "@ant-design/icons";

const Home = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <Result icon={<LoginOutlined />} title={t("Log in to Songtradr Admin")} />
  );
};

export default Home;
