import React, { ReactElement, useState, useCallback } from "react";
import { IOrganization } from "src/interfaces/organization";
import { useMountedRef } from "@songtradr/spa-common/lib/utils";
import { getOrganizations } from "../../../api";
import useAuth from "../../../auth/use-auth";
import Organizations from "..";

const OrganizationsContainer = (): ReactElement => {
  const { getAccessToken } = useAuth();
  const isMountedRef = useMountedRef();
  const [organizations, setOrganization] = useState<Array<IOrganization>>([]);
  const [isError, setIsError] = useState<boolean>(false);

  const getData = useCallback(async () => {
    try {
      if (getAccessToken) {
        setOrganization([]);
        const accessToken = await getAccessToken();
        const organizationResponse = await getOrganizations(accessToken);
        if (isMountedRef.current) setOrganization(organizationResponse);
      }
    } catch (e) {
      if (isMountedRef.current) setIsError(true);
    }
  }, [isMountedRef, getAccessToken]);

  return (
    <Organizations
      organizations={organizations}
      getData={getData}
      isError={isError}
    />
  );
};

export default OrganizationsContainer;
