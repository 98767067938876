import React, { useEffect, useState, ReactElement } from "react";
import { useTranslation, Trans } from "react-i18next";
import { debounce } from "lodash";
import { Form, Input, Divider, Button, notification, message } from "antd";
import { convertDataToFields } from "@songtradr/spa-common/lib/utils";
import { IOrganization } from "src/interfaces/organization";
import ReadOnlyField from "../../../../components/read-only-field";
import OrganizationTypeSelect from "../../../../components/organization-type-select";
import ParentCompanySearchSelect from "../../../../components/parent-company-search-select/container";
import styles from "./styles";

const FIELDS = {
  name: ["name"],
  orgType: ["orgType"],
  status: ["status"],
  parentCompany: ["parentCompany"],
};

const namespace = "organization-form";

interface IProps {
  data: IOrganization;
  onChange: (updatedOrganization: IOrganization) => void;
  onSave: () => Promise<void>;
  isSavePending: boolean;
}

const OrganizationForm = ({
  data,
  onChange,
  onSave,
  isSavePending,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [hasRendered, setHasRendered] = useState(false);
  const [form] = Form.useForm();
  const fields = convertDataToFields(FIELDS, data);

  useEffect(() => {
    void form.validateFields();
    setHasRendered(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValuesChange = debounce((changed, updatedFields) => {
    onChange(updatedFields);
  }, 200);

  const hasErrors = () =>
    hasRendered && form.getFieldsError().some(({ errors }) => errors.length);

  const onFormSubmit = async () => {
    try {
      await onSave();
      const { name } = data;
      void message.success(
        <Trans i18nKey="editOrganization##success">
          Organization <strong>{{ name }}</strong> updated successfully
        </Trans>
      );
    } catch (e) {
      notification.error({
        message: t("Error updating organization"),
        description: t(
          "An error has ocurred when updating the organization. Please refresh the page and try again."
        ),
        duration: 0,
      });
    }
  };

  return (
    <Form
      className={namespace}
      fields={fields}
      form={form}
      labelCol={{ span: 5 }}
      onValuesChange={onValuesChange}
      wrapperCol={{ span: 19 }}
      css={styles.form}
      onFinish={onFormSubmit}
    >
      <Form.Item
        label={t("Name")}
        name={FIELDS.name}
        rules={[
          {
            required: true,
            whitespace: true,
            message: t(
              "editOrganization##validation##Please provide an organization name"
            ),
          },
        ]}
      >
        <Input maxLength={80} />
      </Form.Item>
      <Form.Item
        label={t("Organization Type")}
        name={FIELDS.orgType}
        rules={[
          {
            required: true,
            whitespace: true,
            message: t(
              "editOrganization##validation##Please provide an organization type"
            ),
          },
        ]}
      >
        <OrganizationTypeSelect />
      </Form.Item>
      <Form.Item label={t("Parent Company")} name={FIELDS.parentCompany}>
        <ParentCompanySearchSelect />
      </Form.Item>
      <Form.Item label={t("Status")} name={FIELDS.status}>
        <ReadOnlyField />
      </Form.Item>
      <Divider />
      <Form.Item
        className={`${namespace}__controls`}
        wrapperCol={{ span: 24 }}
        css={styles.controls}
      >
        <Button
          type="primary"
          htmlType="submit"
          disabled={hasErrors()}
          loading={isSavePending}
        >
          {t("editOrganization##Update organization")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OrganizationForm;
