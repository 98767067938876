/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useCallback, ReactElement } from "react";
import { getUser, getOrganization, updateOrganization } from "src/api";
import useAuth from "src/auth/use-auth";
import { IOrganization } from "src/interfaces/organization";
import { IVinylUser } from "src/api/get-user";
import { useParams } from "react-router-dom";
import { useMountedRef } from "@songtradr/spa-common/lib/utils";
import { updateOrganizationAdmins } from "../utils";
import User from "..";

interface IParams {
  userId: string;
  organizationId: string;
}

const UsersContainer = (): ReactElement => {
  const { userId, organizationId } = useParams<IParams>();
  const { getAccessToken } = useAuth();
  const isMountedRef: React.MutableRefObject<boolean> = useMountedRef();
  const [user, setUser] = useState<IVinylUser>();
  const [isError, setIsError] = useState<boolean>(false);
  const [organization, setOrganization] = useState<IOrganization>();
  const [isSavePending, setIsSavePending] = useState(false);

  const getData = useCallback(async () => {
    setUser(undefined);

    try {
      const accessToken: string = await (getAccessToken as () => Promise<string>)();
      const [userResponse, organizationResponse] = await Promise.all([
        getUser(accessToken, userId, organizationId),
        getOrganization({ accessToken, organizationId }),
      ]);
      if (!isMountedRef.current) return;
      setOrganization(organizationResponse);
      const admin = organizationResponse.adminUsers.find(
        ({ email }) => email === userResponse.email
      );
      setUser({
        ...userResponse,
        isAdmin: !!admin,
        phoneNumber: admin ? admin.phone : undefined,
      });
    } catch (e) {
      if (!isMountedRef.current) return;
      setIsError(true);
      setOrganization(undefined);
      setOrganization(undefined);
    }
  }, [isMountedRef, getAccessToken, userId, organizationId]);

  const onChange = (updatedUser: IVinylUser) => {
    setUser({ ...user, ...updatedUser });
  };

  const onSave = async () => {
    setIsSavePending(true);

    updateOrganizationAdmins(user!.isAdmin as boolean, user!, organization!);

    try {
      const accessToken: string = await (getAccessToken as () => Promise<string>)();
      await updateOrganization({
        accessToken,
        organizationId,
        organizationUpdates: updateOrganizationAdmins(
          user!.isAdmin!,
          user!,
          organization!
        ),
      });
    } finally {
      setIsSavePending(false);
    }
  };

  return (
    <User
      user={user}
      isError={isError}
      getData={getData}
      onChange={onChange}
      onSave={onSave}
      isSavePending={isSavePending}
    />
  );
};

export default UsersContainer;
