import React, { ReactElement, ReactNode } from "react";
import { Link } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { IUserTableRow } from "src/interfaces/users-table";
import styles from "./styles";

const getColumns = (): ColumnsType<IUserTableRow> => [
  {
    title: "Name",
    dataIndex: "fullName",
    key: "fullName",
    render: (
      name,
      { id, organizationId }: { id: string; organizationId: string }
    ) => (
      <Link to={`/organizations/${organizationId}/users/${id}`}>{name}</Link>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Phone",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    render: (phoneNumber: string) => phoneNumber || "-",
  },
  {
    title: "Admin User",
    dataIndex: "isAdmin",
    key: "isAdmin",
    render: (isAdmin: boolean) =>
      isAdmin ? <CheckCircleOutlined css={styles.checkIcon} /> : null,
  },
];

export default getColumns;
