import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PageHeader, Table, Skeleton } from "antd";
import {
  IOrganization,
  IDataSourceOrganization,
} from "src/interfaces/organization";
import ErrorAlert from "../../components/error-alert";
import getColumns from "./get-columns";

const tableContentFor = (condition: boolean, emptyText: ReactElement) =>
  condition ? { locale: { emptyText } } : {};

const getDataSource = (
  organizations: Array<IOrganization>
): Array<IDataSourceOrganization> | [] => {
  if (!Array.isArray(organizations)) return [];

  return organizations.map((organization) => ({
    ...organization,
    key: organization.id,
  }));
};

interface IProps {
  organizations: Array<IOrganization>;
  getData: () => Promise<void>;
  isError: boolean;
}

const Organizations = (props: IProps): ReactElement => {
  const { t } = useTranslation();
  const columns = getColumns(t);
  const { organizations, getData, isError } = props;
  const dataSource = getDataSource(organizations);

  useEffect(() => {
    void getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = !organizations;
  const loadingTableProps = tableContentFor(isLoading, <Skeleton active />);

  const errorTableProps = tableContentFor(
    isError,
    <ErrorAlert
      getData={getData}
      errorMessage={t("We've been unable to get the list of organizations.")}
    />
  );

  return (
    <PageHeader ghost={false} title={t("Organizations")}>
      <Table
        columns={columns}
        dataSource={dataSource}
        {...loadingTableProps}
        {...errorTableProps}
      />
    </PageHeader>
  );
};

export default Organizations;
