import React, { ReactElement } from "react";
import { Form, Input } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { debounce } from "lodash";
import IOrganisationRequest from "@songtradr/vinyl-common/lib/organisation/request/organisation-request.interface";
import {
  convertDataToFields,
  convertFieldsToData,
  IFieldData,
} from "@songtradr/spa-common/lib/utils";
import { IPatchOrganisationRequestDto } from "src/interfaces/organization-requests";

const FIELDS = {
  rejectedReason: ["rejectedReason"],
};

interface IProps {
  data?: IPatchOrganisationRequestDto;
  onChange: (
    value: React.SetStateAction<boolean>,
    data: IPatchOrganisationRequestDto
  ) => void;
}

const getSummary = (data?: IOrganisationRequest) => {
  if (!data) return null;
  const {
    name,
    requester: { email, role },
  } = data;

  /* TODO - Previously firstName and lastName were available here from data 
  however they're no longer in the response. For now, I've replaced this with email */

  return (
    <Trans i18nKey="rejectOrganization##summary" values={{ name, email, role }}>
      Denying request to create organization <strong>{name}</strong>, requested
      by <strong>{email}</strong> ({role})
    </Trans>
  );
};

const RejectRequestForm = ({ data, onChange }: IProps): ReactElement => {
  const { t } = useTranslation();

  const onFieldsChange = debounce(
    (changed, updatedFields: Array<IFieldData>) => {
      const isValid = updatedFields.every(
        (field) => field.errors?.length === 0
      );
      onChange(
        isValid,
        convertFieldsToData(updatedFields) as IPatchOrganisationRequestDto
      );
    },
    200
  );

  const fields = convertDataToFields(FIELDS, data);

  return (
    <Form layout="vertical" fields={fields} onFieldsChange={onFieldsChange}>
      <Form.Item>{getSummary(data)}</Form.Item>
      <Form.Item
        label={t("rejectOrganization##labels##Reason for rejection")}
        name="rejectedReason"
        rules={[
          {
            required: true,
            message: t(
              "rejectOrganization##validation##Please provide a reason for rejecting the request"
            ),
          },
        ]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>
    </Form>
  );
};

export default RejectRequestForm;
