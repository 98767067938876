import React from "react";
import ReactDOM from "react-dom";
import { withInternationalization } from "@songtradr/spa-common";
import withAuthentication from "./auth/with-authentication";
import App from "./app";
import en from "./translations/en.json";

const AuthenticatedApp = withAuthentication(
  withInternationalization(App, JSON.stringify(en))
);

ReactDOM.render(
  <React.StrictMode>
    <AuthenticatedApp />
  </React.StrictMode>,
  document.getElementById("root")
);
