import React from "react";
import { Select, Spin, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import { IOrganization } from "src/interfaces/organization";
import { RefSelectProps } from "antd/lib/select";

interface IProps {
  value?: {
    id: string;
    name: string;
    isLoading: boolean;
  };
  isFetching: boolean;
  parentCompanies: Array<IOrganization>;
  searchTerm?: string;
  onSearch: (name: string) => void;
  onChange?: (updatedOrganization: { id: string; name: string } | null) => void;
}

const ParentCompanySearchSelect = React.forwardRef<RefSelectProps, IProps>(
  (
    {
      value,
      isFetching,
      parentCompanies,
      searchTerm,
      onSearch,
      onChange,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();

    if (value && value.isLoading) return <Skeleton.Input active />;

    const getEmptyContent = () => {
      if (isFetching) return <Spin size="small" />;
      if (searchTerm) return t("No Results");
      return null;
    };

    return (
      <Select
        ref={ref}
        labelInValue
        value={value ? { value: value.id, label: value.name } : undefined}
        allowClear
        placeholder={t("No Parent Company")}
        filterOption={false}
        notFoundContent={getEmptyContent()}
        showSearch
        onBlur={() => onSearch("")}
        onSearch={onSearch}
        onChange={(selection) => {
          if (!selection) return onChange && onChange(null);
          return (
            onChange && onChange({ id: selection.value, name: selection.label })
          );
        }}
        {...rest}
      >
        {parentCompanies.map(({ id, name }) => (
          <Select.Option value={id} key={id}>
            {name}
          </Select.Option>
        ))}
      </Select>
    );
  }
);

export default ParentCompanySearchSelect;
