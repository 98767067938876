import React from "react";
import { Select } from "antd";
import styles from "./styles";
import icon from "./images/drop-down-arrow.svg";

interface IProps {
  options: { value: string; label: string }[];
  placeholder: string;
}

const VinylSelect = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: IProps, ref: React.ForwardedRef<any>) => (
    <Select
      ref={ref}
      dropdownMatchSelectWidth={false}
      suffixIcon={<img css={styles.icon} src={icon} alt="" />}
      size="large"
      {...props}
    >
      {props.options.map(({ value, label }) => (
        <Select.Option key={value} value={value}>
          {label}
        </Select.Option>
      ))}
    </Select>
  )
);

export default VinylSelect;
