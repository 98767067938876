import React, { Key, ReactElement } from "react";
import { Dropdown, Button, Menu, Space } from "antd";
import {
  DownOutlined,
  DeleteOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { ModalActions } from "src/constants/organization-requests";
import { useTranslation } from "react-i18next";
import { IRequestDataSource } from "src/interfaces/organization-requests";
import styles from "./styles";

const handleAction = (key: Key, request: IRequestDataSource) => {
  if (key === ModalActions.approve) request.approveRequest(request);
  if (key === ModalActions.reject) request.rejectRequest(request);
};

const namespace = "organization-request-actions";

const ActionDropdown = ({
  request,
}: {
  request: IRequestDataSource;
}): ReactElement => {
  const { t } = useTranslation();

  const actionMenu = (
    <Menu
      onClick={({ key }) => handleAction(key, request)}
      className={namespace}
    >
      <Menu.Item
        key={ModalActions.approve}
        className={`${namespace}__approve-action`}
      >
        <Space>
          <UsergroupAddOutlined
            className={`${namespace}__approve-action-icon`}
            css={styles.approve}
          />
          {t("Approve")}
        </Space>
      </Menu.Item>
      <Menu.Item
        key={ModalActions.reject}
        className={`${namespace}__reject-action`}
      >
        <Space>
          <DeleteOutlined
            className={`${namespace}__reject-action-icon`}
            css={styles.reject}
          />
          {t("Reject")}
        </Space>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={actionMenu} trigger={["click"]}>
      <Button>
        {t("Actions")} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ActionDropdown;
