import { css } from "@emotion/react";

export default {
  icon: css`
    margin-top: -0.3rem;
    margin-left: -0.4rem;
    transition: transform 0.3s;

    .ant-select-open & {
      transform: rotate(0.5turn);
    }
  `,
};
