import axios from "axios";
import { IOrganization } from "src/interfaces/organization";
import config from "../config";
import { getAuthHeader, getJsonContentHeader } from "./headers";

interface IProps {
  accessToken: string;
  organizationId: string;
}

function getOrganization({
  accessToken,
  organizationId,
}: IProps): Promise<IOrganization> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };
  return axios
    .get(
      `${config.organizationsService.url}/organisations/${organizationId}`,
      options
    )
    .then(({ data }) => data as IOrganization);
}

export default getOrganization;
