import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Menu, Button, Dropdown, Avatar, Space } from "antd";
import { IAuthenticatedUser } from "@songtradr/spa-common/lib/interfaces";
import styles from "./styles";

interface IProps {
  isAuthenticated: boolean;
  onLogInOut: (isAuthenticated: boolean) => void;
  user?: IAuthenticatedUser | null;
}

function CurrentUserNavigation({
  isAuthenticated,
  user,
  onLogInOut,
}: IProps): ReactElement {
  const { t } = useTranslation();
  const onClickLogInOut = () => onLogInOut(isAuthenticated);

  if (!isAuthenticated) {
    return (
      <div>
        <Button type="primary" onClick={onClickLogInOut}>
          {t("Log in")}
        </Button>
      </div>
    );
  }

  const menu = (
    <Menu>
      <Menu.Item key="log-out" onClick={onClickLogInOut}>
        {t("Log out")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <button type="button" css={styles.menu}>
        <Space>
          {user && user.nickName}
          <Avatar src={user && user.picture} css={styles.avatar} />
        </Space>
      </button>
    </Dropdown>
  );
}

export default CurrentUserNavigation;
