import axios from "axios";
import { OrganisationRequestStatus } from "src/pages/organization-requests/utils";
import config from "../config";
import { getAuthHeader, getJsonContentHeader } from "./headers";

function updateOrganizationRequest(
  accessToken: string,
  requestId: string,
  {
    status,
    rejectedReason,
  }: { status: OrganisationRequestStatus; rejectedReason?: string }
): Promise<Response> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };
  return axios
    .patch(
      `${config.organizationsService.url}/organisations/requests/${requestId}`,
      { status, ...(rejectedReason ? { rejectedReason } : {}) },
      options
    )
    .then(({ data }) => data as Response);
}

export default updateOrganizationRequest;
