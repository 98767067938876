import React, { ReactElement } from "react";
import { useTranslation, Trans } from "react-i18next";
import { debounce } from "lodash";
import { Form, Divider, Button, Switch, notification, message } from "antd";
import ReadOnlyField from "src/components/read-only-field";
import { IVinylUser } from "src/api/get-user";
import {
  convertDataToFields,
  convertFieldsToData,
} from "@songtradr/spa-common/lib/utils";
import styles from "./styles";

interface IProps {
  data: IVinylUser;
  onChange: (updatedData: IVinylUser) => void;
  onSave: () => Promise<void>;
  isSavePending: boolean;
}

const FIELDS = {
  fullName: ["fullName"],
  emailAddress: ["email"],
  phoneNumber: ["phoneNumber"],
  isAdmin: ["isAdmin"],
};

const namespace = "user-form";

const UserForm = ({
  data,
  onChange,
  onSave,
  isSavePending,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  const onFieldsChange = debounce((changed, updatedFields) => {
    onChange(convertFieldsToData(updatedFields) as IVinylUser);
  }, 200);

  const fields = convertDataToFields(FIELDS, data);

  const onFormSubmit = async () => {
    try {
      await onSave();
      const { firstName, lastName } = data;
      await message.success(
        <Trans i18nKey="editUser##success">
          User{" "}
          <strong>
            {firstName} {lastName}
          </strong>{" "}
          updated successfully
        </Trans>
      );
    } catch (e) {
      notification.error({
        message: t("Error updating user"),
        description: t(
          "An error has ocurred when updating the user. Please refresh the page and try again."
        ),
        duration: 0,
      });
    }
  };

  return (
    <Form
      fields={fields}
      onFieldsChange={onFieldsChange}
      className={namespace}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      onFinish={onFormSubmit}
      css={styles.form}
    >
      <Form.Item label={t("Name")} name={FIELDS.fullName}>
        <ReadOnlyField />
      </Form.Item>
      <Form.Item label={t("Email")} name={FIELDS.emailAddress}>
        <ReadOnlyField />
      </Form.Item>
      <Form.Item label={t("Phone")} name={FIELDS.phoneNumber}>
        <ReadOnlyField />
      </Form.Item>
      <Form.Item
        label={t("Admin User")}
        name={FIELDS.isAdmin}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Divider />
      <Form.Item
        className={`${namespace}__controls`}
        wrapperCol={{ span: 24 }}
        css={styles.controls}
      >
        <Button type="primary" htmlType="submit" loading={isSavePending}>
          {t("editUser##Update user")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
