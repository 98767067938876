import { eAuthType } from "@songtradr/spa-common/lib/enums";
import {
  IAuthenticatedUser,
  IOauthUser,
} from "@songtradr/spa-common/lib/interfaces";
import history from "../utils/history";

const hasParameter = (parameterName: string) =>
  window.location.search.includes(`${parameterName}=`);

const isSuccessfulLogin = () => hasParameter("state") && hasParameter("code");

const isFailedLogin = () =>
  hasParameter("state") &&
  hasParameter("error") &&
  hasParameter("error_description");

export const isRedirectedBackFromLogin = (): boolean =>
  isSuccessfulLogin() || isFailedLogin();

export const onRedirectCallback = (state?: { targetPath?: string }): void => {
  history.push(state && state.targetPath ? state.targetPath : "/");
};

export const getRedirectConfig = (
  targetPath?: string
): { appState: { targetPath?: string; basePath: string } } => ({
  appState: { targetPath, basePath: window.location.pathname },
});

export const getRedirectTargetPath = (targetPath?: string): string =>
  new URL(targetPath || "/", window.location.origin).pathname;

export const getRedirectUri = (url?: string): string =>
  new URL(url || "/", window.location.origin).href;

export const transformUser = ({
  sub: id,
  nickName,
  name,
  picture,
  updated_at: updatedAt,
  email,
  email_verified: isVerifiedEmail,
}: IOauthUser): IAuthenticatedUser => {
  return {
    id,
    type: id.substring(0, id.indexOf("|")) as eAuthType,
    nickName,
    name,
    picture,
    updatedAt,
    email,
    isVerifiedEmail,
  };
};
