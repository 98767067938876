import axios from "axios";
import { omit } from "lodash";
import { IOrganization } from "src/interfaces/organization";
import config from "../config";
import { getAuthHeader, getJsonContentHeader } from "./headers";

interface IProps {
  accessToken: string;
  organizationId: string;
  organizationUpdates: IOrganization;
}

function updateOrganization({
  accessToken,
  organizationId,
  organizationUpdates,
}: IProps): unknown {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };
  return axios
    .put(
      `${config.organizationsService.url}/organisations/${organizationId}`,
      omit(organizationUpdates, "audit"),
      options
    )
    .then(({ data }) => data as IOrganization);
}

export default updateOrganization;
