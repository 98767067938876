import { css } from "@emotion/react";

export default {
  app: css`
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    * {
      box-sizing: border-box;
    }

    main {
      flex: 1;
    }

    section {
      background: #fff;
    }

    label {
      font-weight: bold;
    }

    .ant-modal-root {
      label {
        font-weight: bold;

        > * {
          font-weight: normal;
        }
      }
    }
  `,
};
