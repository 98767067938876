import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { Form } from "antd";
import ReadOnlyField from "src/components/read-only-field";
import IOrganisationRequest from "@songtradr/vinyl-common/lib/organisation/request/organisation-request.interface";
import {
  convertDataToFields,
  convertFieldsToData,
  IFieldData,
} from "@songtradr/spa-common/lib/utils";

const FIELDS = {
  name: ["name"],
  type: ["orgType"],
  requesterRole: ["requester", "role"],
  requesterEmailAddress: ["requester", "email"],
  requesterPhoneNumber: ["requester", "phone"],
};

interface IRefProps {
  value?: string;
}

interface IProps {
  data?: IOrganisationRequest;
  onChange: (
    value: React.SetStateAction<boolean>,
    data: IOrganisationRequest | undefined
  ) => void;
}

const NewOrganizationForm = ({ data, onChange }: IProps): ReactElement => {
  const { t } = useTranslation();

  const ReadOnlyOrganizationType = React.forwardRef<
    HTMLButtonElement,
    IRefProps
  >(({ value }, ref) => (
    <span ref={ref}>
      {value ? <>{t(`organizationTypes##${value}`)}</> : null}
    </span>
  ));

  const ReadOnlyRequesterRole = React.forwardRef<HTMLButtonElement, IRefProps>(
    ({ value }, ref) => (
      <span ref={ref}>{value ? t(`requesterRoles##${value}`) : null}</span>
    )
  );

  const [form] = Form.useForm();
  useEffect(() => {
    form
      .validateFields()
      .then(() => {
        onChange(true, data);
      })
      .catch(() => {
        onChange(false, data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFieldsChange = debounce(
    (changed: Array<IFieldData>, updatedFields: Array<IFieldData>) => {
      const isValid = updatedFields.every(
        (field) => field.errors?.length === 0
      );

      onChange(
        isValid,
        convertFieldsToData(updatedFields) as IOrganisationRequest
      );
    },
    200
  );

  const fields = convertDataToFields(FIELDS, data);

  return (
    <Form
      form={form}
      layout="vertical"
      fields={fields}
      onFieldsChange={onFieldsChange}
    >
      <Form.Item
        label={t("createOrganization##labels##Organization Name")}
        name={FIELDS.name}
      >
        <ReadOnlyField />
      </Form.Item>
      <Form.Item
        label={t("createOrganization##labels##Organization Type")}
        name={FIELDS.type}
      >
        <ReadOnlyOrganizationType />
      </Form.Item>
      <Form.Item
        label={t("createOrganization##labels##Admin Role")}
        name={FIELDS.requesterRole}
      >
        <ReadOnlyRequesterRole />
      </Form.Item>
      <Form.Item
        label={t("createOrganization##labels##Admin Email Address")}
        name={FIELDS.requesterEmailAddress}
      >
        <ReadOnlyField />
      </Form.Item>
      <Form.Item
        label={t("createOrganization##labels##Admin Phone Number")}
        name={FIELDS.requesterPhoneNumber}
      >
        <ReadOnlyField />
      </Form.Item>
    </Form>
  );
};

export default NewOrganizationForm;
