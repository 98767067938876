import React, { useState, useCallback, ReactElement } from "react";
import { IOrganization } from "src/interfaces/organization";
import { IVinylUser } from "src/api/get-user";
import { useMountedRef } from "@songtradr/spa-common/lib/utils";
import { getOrganization, getUsers, updateOrganization } from "../../../api";
import useAuth from "../../../auth/use-auth";
import {
  setParentCompanyLoading,
  setParentCompanyLoaded,
  getUpdatedParentCompany,
} from "../utils";
import Organization from "..";

interface IProps {
  id: string;
}

const OrganizationContainer = ({ id }: IProps): ReactElement => {
  const { getAccessToken } = useAuth();
  const isMountedRef = useMountedRef();
  const [organization, setOrganization] = useState<IOrganization>();
  const [users, setUsers] = useState<IVinylUser[]>();
  const [isSavePending, setIsSavePending] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getOrganizationData = useCallback(
    async (accessToken: string, organizationId: string) => {
      if (!isMountedRef.current) return;
      try {
        const organizationResponse = await getOrganization({
          accessToken,
          organizationId,
        });
        if (isMountedRef.current) setOrganization(organizationResponse);
      } catch (e) {
        if (isMountedRef.current) setIsError(true);
      }
    },
    [isMountedRef]
  );

  const getUsersData = useCallback(
    async (accessToken, organizationId) => {
      if (!isMountedRef.current) return;
      try {
        const usersResponse = await getUsers(accessToken, organizationId);
        if (isMountedRef.current) setUsers(usersResponse);
      } catch (e) {
        if (isMountedRef.current) setUsers(e);
      }
    },
    [isMountedRef]
  );

  const getData = useCallback(async () => {
    try {
      if (!getAccessToken) throw new Error("Access token is undefined");
      const accessToken = await getAccessToken();
      const organizationResponse = await getOrganization({
        accessToken,
        organizationId: id,
      });
      if (!isMountedRef.current) return;
      void getOrganizationData(accessToken, id);
      void getUsersData(accessToken, id);

      const hasParentCompany =
        organizationResponse.parentCompany &&
        organizationResponse.parentCompany.id;
      if (!hasParentCompany) {
        setOrganization(organizationResponse);
        return;
      }

      setOrganization(setParentCompanyLoading(organizationResponse));
      const parentCompany = await getOrganization({
        accessToken,
        organizationId: organizationResponse.parentCompany.id,
      });
      if (isMountedRef.current) {
        setOrganization(
          setParentCompanyLoaded(organizationResponse, parentCompany)
        );
      }
    } catch (e) {
      if (isMountedRef.current) {
        setIsError(true);
      }
    }
  }, [isMountedRef, getOrganizationData, getUsersData, getAccessToken, id]);

  const onChange = (updatedOrganization: IOrganization) => {
    if (!organization) return;
    const parentCompany = getUpdatedParentCompany(
      organization,
      updatedOrganization
    );
    setOrganization({
      ...organization,
      ...updatedOrganization,
      ...(parentCompany && { parentCompany }),
    });
  };

  const onSave = async () => {
    setIsSavePending(true);

    try {
      if (!getAccessToken) throw new Error("Access token is undefined");
      if (!organization) return;
      const accessToken = await getAccessToken();
      await updateOrganization({
        accessToken,
        organizationId: id,
        organizationUpdates: organization,
      });
    } finally {
      setIsSavePending(false);
    }
  };

  return (
    <Organization
      organization={organization}
      users={users}
      getData={getData}
      onChange={onChange}
      onSave={onSave}
      isSavePending={isSavePending}
      isError={isError}
    />
  );
};

export default OrganizationContainer;
