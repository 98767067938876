import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";
import { IOrganization } from "src/interfaces/organization";
import ErrorAlert from "../../../../components/error-alert";
import OrganizationForm from "./organization-form";

interface IProps {
  getData: () => void;
  data: IOrganization;
  onChange: (updatedOrganization: IOrganization) => void;
  onSave: () => Promise<void>;
  isSavePending: boolean;
}

const OrganizationFormWrapper = ({
  data,
  getData,
  onChange,
  onSave,
  isSavePending,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  const isLoading = !data;
  const isError = data instanceof Error;

  if (isLoading) return <Skeleton active />;
  if (isError)
    return (
      <ErrorAlert
        getData={getData}
        errorMessage={t(
          "We've been unable to get the details for this organization."
        )}
      />
    );

  return (
    <OrganizationForm
      data={data}
      onChange={onChange}
      onSave={onSave}
      isSavePending={isSavePending}
    />
  );
};

export default OrganizationFormWrapper;
