import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";

const getErrorTitle = (t: (key: string) => string, error = { error: "" }) => {
  switch (error.error) {
    case "server_error":
      return t("Error logging in");
    default:
      return t("Unknown Error");
  }
};

const getErrorDetails = (t: (key: string) => string, error = { error: "" }) => {
  switch (error.error) {
    case "server_error":
      return t(
        "An error has occurred when attempting to log you in. Please try again."
      );
    default:
      return t("An unknown error has occurred. Please try again.");
  }
};

interface IProps {
  error: {
    error: string;
    error_description: string;
  };
  onDismiss: (() => void) | undefined;
}

const PageError = ({ error, onDismiss }: IProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Alert
      message={getErrorTitle(t, error)}
      description={getErrorDetails(t, error)}
      type="error"
      showIcon
      closable
      onClose={onDismiss}
    />
  );
};

export default PageError;
