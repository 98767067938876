import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { PageHeader, Result, Button } from "antd";
import { useTranslation } from "react-i18next";

const NotFound = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <PageHeader ghost={false} title={t("Not found")}>
      <Result
        status="404"
        title={t("Not found")}
        subTitle={t("Sorry, we can't find this page.")}
        extra={
          <Button type="primary" onClick={() => history.push("/")}>
            {t("Go to the main page")}
          </Button>
        }
      />
    </PageHeader>
  );
};

export default NotFound;
