import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PageHeader } from "antd";
import { IVinylUser } from "src/api/get-user";
import { IOrganization } from "src/interfaces/organization";
import NotFound from "../not-found";
import OrganizationForm from "./components/organization-form";
import UsersTable from "./components/users-table";

interface IProps {
  organization?: IOrganization;
  users?: Array<IVinylUser>;
  getData: () => void;
  onChange: (updatedOrganization: IOrganization) => void;
  onSave: () => Promise<void>;
  isSavePending: boolean;
  isError: boolean;
}
const Organization = (props: IProps): ReactElement => {
  const { t } = useTranslation();
  const {
    organization,
    users,
    getData,
    onChange,
    onSave,
    isSavePending,
    isError,
  } = props;

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isError || !organization) {
    return <NotFound />;
  }

  const renderContent = () => {
    return (
      <>
        <OrganizationForm
          data={organization}
          getData={getData}
          onChange={onChange}
          onSave={onSave}
          isSavePending={isSavePending}
        />
        <UsersTable
          getData={getData}
          organization={organization}
          users={users}
          isError={isError}
        />
      </>
    );
  };

  return (
    <PageHeader ghost={false} title={t("Edit Organization")}>
      {renderContent()}
    </PageHeader>
  );
};

export default Organization;
