import React from "react";
import { useTranslation } from "react-i18next";
import { OrganizationTypes } from "src/constants/";
import Select from "../select";

const OrganizationTypeSelect = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <Select
      ref={ref}
      placeholder={t("Select your organization type")}
      options={Object.keys(OrganizationTypes).map((key) => ({
        value: key,
        label: t(`organizationTypes##${key}`),
      }))}
      {...props}
    />
  );
});

export default OrganizationTypeSelect;
