import React, { ReactElement, useEffect } from "react";
import { PageHeader, Table, Skeleton } from "antd";
import {
  IPatchOrganisationRequestDto,
  IRequestDataSource,
} from "src/interfaces/organization-requests";
import { useTranslation } from "react-i18next";
import IOrganisationRequest from "@songtradr/vinyl-common/lib/organisation/request/organisation-request.interface";
import ErrorAlert from "../../components/error-alert";
import getColumns from "./get-columns";

const tableContentFor = (condition: boolean, emptyText: ReactElement) =>
  condition ? { locale: { emptyText } } : {};
interface IGetDataSourceProps {
  requests: Array<IPatchOrganisationRequestDto>;
  approveRequest: (request: IOrganisationRequest) => void;
  rejectRequest: (request: IOrganisationRequest) => void;
}

const getDataSource = ({
  requests,
  approveRequest,
  rejectRequest,
}: IGetDataSourceProps): Array<IRequestDataSource> | [] => {
  if (!Array.isArray(requests)) return [];

  return requests.map((request) => ({
    ...request,
    key: request.id,
    approveRequest,
    rejectRequest,
  }));
};
interface IProps {
  requests: Array<IPatchOrganisationRequestDto>;
  getData: () => Promise<void>;
  approveRequest: (request: IOrganisationRequest) => void;
  rejectRequest: (request: IOrganisationRequest) => void;
}

const OrganizationRequests = ({
  requests,
  getData,
  approveRequest,
  rejectRequest,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  useEffect(() => {
    void getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = !requests;
  const loadingTableProps = tableContentFor(isLoading, <Skeleton active />);

  const isError = requests instanceof Error;
  const errorTableProps = tableContentFor(
    isError,
    <ErrorAlert
      getData={getData}
      errorMessage={t(
        "We've been unable to get the list of organization requests."
      )}
    />
  );

  const dataSource = getDataSource({ requests, approveRequest, rejectRequest });
  const columns = getColumns(t);

  return (
    <PageHeader ghost={false} title={t("Organization Requests")}>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ hideOnSinglePage: true }}
        {...loadingTableProps}
        {...errorTableProps}
      />
    </PageHeader>
  );
};

export default OrganizationRequests;
