import React, { ReactElement, useState, useCallback } from "react";
import ActionModal from "src/pages/organization-requests/components/action-modal";
import { useMountedRef } from "@songtradr/spa-common/lib/utils";
import { ModalActions } from "src/constants/organization-requests";
import { OrganisationRequestStatus } from "src/pages/organization-requests/utils";
import { IPatchOrganisationRequestDto } from "src/interfaces/organization-requests";
import IOrganisationRequest from "@songtradr/vinyl-common/lib/organisation/request/organisation-request.interface";
import {
  getOrganizationRequests,
  updateOrganizationRequest,
} from "../../../api";
import useAuth from "../../../auth/use-auth";
import OrganizationRequests from "..";

const OrganizationRequestsContainer = (): ReactElement => {
  const [requests, setRequests] = useState<Array<IPatchOrganisationRequestDto>>(
    []
  );
  const [
    activeRequest,
    setActiveRequest,
  ] = useState<IPatchOrganisationRequestDto>();

  const [
    activeNewOrgRequest,
    setActiveNewOrgRequest,
  ] = useState<IOrganisationRequest>();

  const [action, setAction] = useState<ModalActions>();
  const [isActionPending, setIsActionPending] = useState(false);
  const { getAccessToken, isAuthenticated } = useAuth();
  const isMountedRef = useMountedRef();

  const getData = useCallback(async () => {
    setRequests([]);

    if (isAuthenticated && getAccessToken) {
      try {
        const accessToken = await getAccessToken();
        const requestsResponse = await getOrganizationRequests(accessToken);
        if (isMountedRef.current) setRequests(requestsResponse);
      } catch (e) {
        if (isMountedRef.current) setRequests(e);
      }
    }
  }, [isAuthenticated, isMountedRef, getAccessToken]);

  const approveRequest = useCallback((request) => {
    setActiveRequest(request);
    setAction(ModalActions.approve);
  }, []);

  const rejectRequest = useCallback((request) => {
    setActiveRequest(request);
    setAction(ModalActions.reject);
  }, []);

  function updateActiveRequest(
    updatedActiveRequest: IPatchOrganisationRequestDto
  ) {
    setActiveRequest({
      ...activeRequest,
      ...updatedActiveRequest,
    });
  }

  function createNewActiveRequest(newOrgRequest: IOrganisationRequest) {
    setActiveNewOrgRequest({
      ...activeNewOrgRequest,
      ...newOrgRequest,
    });
  }

  const onDismissAction = () => {
    setAction(undefined);
    setActiveRequest(undefined);
    setIsActionPending(false);
  };

  const onConfirmAction = async () => {
    if (isAuthenticated && getAccessToken && activeRequest) {
      setIsActionPending(true);

      try {
        const accessToken = await getAccessToken();
        if (action === ModalActions.approve) {
          await updateOrganizationRequest(accessToken, activeRequest.id, {
            status: OrganisationRequestStatus.Approved,
          });
        }
        if (action === ModalActions.reject) {
          await updateOrganizationRequest(accessToken, activeRequest.id, {
            status: OrganisationRequestStatus.Rejected,
            rejectedReason: activeRequest.rejectedReason,
          });
        }
      } finally {
        onDismissAction();
      }

      await getData();
    }
  };

  return (
    <>
      <OrganizationRequests
        requests={requests}
        getData={getData}
        approveRequest={approveRequest}
        rejectRequest={rejectRequest}
      />
      <ActionModal
        activeRequest={activeRequest}
        action={action}
        updateActiveRequest={updateActiveRequest}
        createNewActiveRequest={createNewActiveRequest}
        onConfirmAction={onConfirmAction}
        onDismissAction={onDismissAction}
        isActionPending={isActionPending}
      />
    </>
  );
};

export default OrganizationRequestsContainer;
