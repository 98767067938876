import { Layout } from "antd";
import React, { ReactElement } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import Header from "src/pages/header";
import Home from "src/pages/home";
import NotFound from "src/pages/not-found";
import OrganizationRequests from "src/pages/organization-requests/container";
import Organization from "src/pages/organization/container";
import Organizations from "src/pages/organizations/container";
import User from "src/pages/user/container";
import AuthenticatedRoute from "../auth/authenticated-route";
import useAuth from "../auth/use-auth";
import PageError from "../components/page-error";
import PageLoading from "../components/page-loading";
import CurrentUserNavigation from "../pages/current-user-navigation";
import styles from "./styles";

interface IMatchParams {
  organizationId: string;
}

type MatchProps = RouteComponentProps<IMatchParams>;

function App(): ReactElement {
  const {
    isLoading,
    isAuthenticated,
    error,
    dismissError,
    user,
    logout,
    login,
  } = useAuth();

  if (isLoading) return <PageLoading />;

  const isLoggedIn = isAuthenticated as boolean;
  const onLogInOut = () => (isLoggedIn ? logout && logout() : login && login());

  const { Footer, Content } = Layout;

  return (
    <div className="app" css={styles.app}>
      <Layout>
        <BrowserRouter>
          <Header isLoggedIn={isLoggedIn}>
            <CurrentUserNavigation
              isAuthenticated={isLoggedIn}
              user={user}
              onLogInOut={onLogInOut}
            />
          </Header>
          {error && (
            <PageError error={error} onDismiss={dismissError && dismissError} />
          )}
          <Content>
            <Switch>
              <Route exact path="/index.html">
                <Redirect to="/" />
              </Route>
              <Route exact path="/">
                {isAuthenticated ? (
                  <Redirect to="/organization-requests" />
                ) : (
                  <Home />
                )}
              </Route>
              <AuthenticatedRoute exact path="/organization-requests">
                <OrganizationRequests />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/organizations">
                <Organizations />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/organizations/:organizationId">
                {({ match }: MatchProps) => (
                  <Organization id={match?.params.organizationId} />
                )}
              </AuthenticatedRoute>
              <AuthenticatedRoute
                exact
                path="/organizations/:organizationId/users/:userId"
              >
                <User />
              </AuthenticatedRoute>
              <Route path="/">
                <NotFound />
              </Route>
            </Switch>
          </Content>
          <Footer>Footer</Footer>
        </BrowserRouter>
      </Layout>
    </div>
  );
}

export default App;
